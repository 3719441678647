<template>
  <div>
    <!--------------------面包屑导航-------------------->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学校概况</el-breadcrumb-item>
      <el-breadcrumb-item>学校简介</el-breadcrumb-item>
    </el-breadcrumb>
    <!----------------------卡片区域---------------------->
    <el-card>
      <el-row :gutter="20"><!--栅格中每个组件的间距，20px-->
        <el-col :span="6"><!--占的比重，一共24-->
          <el-button type="primary" @click="toAddPage()">添加简介</el-button>
        </el-col>
      </el-row>
      <!--------------------用户表格--------------------->
      <el-table :data="tableData" stripe border>
        <el-table-column type="index" label="序号" width="60px"></el-table-column>
        <el-table-column prop="name" label="学校名称"></el-table-column>
        <el-table-column prop="address" label="学校地址"></el-table-column>
        <el-table-column prop="property" label="办学性质"></el-table-column>
        <el-table-column prop="section" label="招生阶段"></el-table-column>
        <el-table-column prop="scale" label="设计规模"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="updateTime" label="最后修改时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editItem(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--------------------查看轮播图对话框--------------------->
      <el-dialog title="首页图片" :visible.sync="bannerDialog"
                 width="70%" @close="bannerDialogClosed">
        <!--内容主体信息-->
        <div class="banner-box">
          <div class="item" v-for="(item, index) in bannerArr" :key="index">
            <img :src="item" alt="" style="height: 100%;"/>
          </div>
        </div>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
        <el-button @click="bannerDialog = false">取 消</el-button>
        <el-button type="primary" @click="bannerDialog = false" :loading="loading">关 闭</el-button>
      </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import '../../assets/quill-font.css';
export default {
  name: 'School',
  data() {
    return {
      tableData: [],//表格数据
      loading: false,//加载效果
      bannerDialog: false,
      schoolInfo: '',
      bannerArr: []
    }
  },
  mounted () {
    this.getList();
  },
  methods: {
    toAddPage() {
      this.$router.push({path:'/school/addSchool', query:{pageTitle:'添加学校简介'}})
    },
    editItem(rows) {
      this.$router.push({path:'/school/addSchool',query:{pageTitle:'编辑学校简介',id:rows.id}})
    },
    bannerDialogClosed() {
      this.schoolInfo = {}
    },
    //查看轮播图
    showBannerDialog(rows) {
      this.bannerDialog = true
      this.schoolInfo = rows
      this.bannerArr = rows.banner.split(",");
    },
    async getList() {
      const result = await this.$http.get('/school/detail')
      let res = result.data
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.tableData.push(res.result)
      }
    },
  }
}
</script>

<style scoped>
.banner-box {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  min-height: 450px;
}
.item {
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
}
</style>
